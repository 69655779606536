<template>
   <switchIdentity
    :list="memberStores"
    :selected="selectedStore"
    title="請選擇要管理的商店"
    register_btn="申請新商店"
    @switchIdentity="clickSwitchIdentity"
    @register="clickRegisterStore"
  ></switchIdentity>
</template>

<script lang="babel" type="text/babel">
export default {
  components: {
    switchIdentity: () => import('@/components/switchIdentity/switchIdentity.vue')
  },
  data: ()=>({
    
  }),
  computed: {
    selectedStore() {
      return this.$store.getters[`member/selectedStore`]
    },
    memberStores() {
      const memberStores = this.$store.getters[`member/memberStores`]
      return memberStores.map((item) => {
        item.id = item.store_id
        item.name = item.store_name
        return item
      })
    },
    layoutProviderId() {
      return this.$store.getters[`base/layoutProviderId`];
    },
  },
  methods: {
    clickSwitchIdentity(item) {
      this.$root.gaLogEvent('商店_點擊_切換商店')
      this.$store.dispatch(`member/switchSelectedStore`, item.id)
    },
    clickRegisterStore() {
      this.$router.push({
        name: 'store-register',
        params: { providerId: this.layoutProviderId },
        query: { forceCreate: true }
      });
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
